import { useEffect, useState, useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { useMediaQuery } from 'react-responsive';
import Company from 'types/company';
import Link from 'next/link';
import { GTagCategory } from 'types/googleAnalytics';

function HeaderBanner(): JSX.Element {
  const [viewportRef, embla] = useEmblaCarousel({
    draggable: true,
    containScroll: 'trimSnaps',
    align: 'start',
    loop: true,
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  // const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)',
  });
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    if (!isDesktop) {
      setTimeout(scrollNext, 3000);
    }
    onSelect();
    embla.on('select', onSelect);
    embla.on('resize', onSelect);
  }, [embla, onSelect, scrollNext, selectedIndex, isDesktop]);

  return (
    <div className="header-banner bg-black-light border-t-2 border-black-lightestx2">
      <div className="header-banner__inner">
        <div className="viewport-banner" ref={viewportRef}>
          <div className="banner__container">
            <div className="banner__slide">
              <a
                href={`https://uk.trustpilot.com/review/www.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk`}
                target="_blank"
                rel="noopener noreferrer"
                data-tracking="usp"
                className="content__link"
                onClick={() => {
                  ga(
                    'send',
                    'event',
                    GTagCategory.Homepage,
                    GTagCategory.PageInteraction,
                    'Trustpilot Link'
                  );
                }}
              >
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                  <path
                    d="M8.034.626L5.837 5.28.922 6.03C.04 6.163-.313 7.298.326 7.949l3.557 3.62-.841 5.115c-.152.924.78 1.617 1.56 1.184L9 15.453l4.397 2.415c.78.429 1.713-.26 1.561-1.184l-.84-5.115 3.556-3.62c.639-.651.286-1.786-.596-1.92l-4.915-.749L9.966.626a1.058 1.058 0 00-1.932 0z"
                    fill="#FFF"
                    fillRule="nonzero"
                  ></path>
                </svg>
                We&apos;re Rated Excellent on Trustpilot
              </a>
            </div>
            <div className="banner__slide">
              <Link href="/information/delivery">
                <a
                  data-tracking="usp"
                  className="content__link"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    ga(
                      'send',
                      'event',
                      GTagCategory.Homepage,
                      GTagCategory.PageInteraction,
                      'Free Next Day Delivery* - Order by 9PM'
                    );
                  }}
                >
                  <svg
                    width="20"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                  >
                    <path
                      d="M19.5 10.8H19V7.287c0-.413-.16-.81-.44-1.102l-3.122-3.247a1.472 1.472 0 00-1.06-.458H13v-.92C13 .699 12.328 0 11.5 0h-10C.672 0 0 .699 0 1.56v9.76c0 .861.672 1.56 1.5 1.56H2C2 14.602 3.344 16 5 16s3-1.398 3-3.12h4c0 1.722 1.344 3.12 3 3.12s3-1.398 3-3.12h1.5c.275 0 .5-.234.5-.52v-1.04a.512.512 0 00-.5-.52zM5 14.44c-.828 0-1.5-.699-1.5-1.56 0-.861.672-1.56 1.5-1.56s1.5.699 1.5 1.56c0 .861-.672 1.56-1.5 1.56zm10 0c-.828 0-1.5-.699-1.5-1.56 0-.861.672-1.56 1.5-1.56s1.5.699 1.5 1.56c0 .861-.672 1.56-1.5 1.56zm2.5-6.76H13V4.04h1.378L17.5 7.287v.393z"
                      fill="#FFF"
                      fillRule="nonzero"
                    ></path>
                  </svg>
                  Free Next Day Delivery*<span> - Order by 9PM</span>
                </a>
              </Link>
            </div>
            <div className="banner__slide">
              <Link href="/information/returns">
                <a
                  data-tracking="usp"
                  className="content__link"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    ga(
                      'send',
                      'event',
                      GTagCategory.Homepage,
                      GTagCategory.PageInteraction,
                      'Easy Free Returns'
                    );
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                  >
                    <text
                      transform="translate(0 -5)"
                      fill="#FFF"
                      fillRule="evenodd"
                      fontSize="24"
                      fontWeight="bold"
                    >
                      <tspan x=".4" y="22">
                        £
                      </tspan>
                    </text>
                  </svg>
                  Easy Free Returns
                </a>
              </Link>
            </div>
          </div>
        </div>
        {/* <button
          className="button--prev hidden md:block xl:hidden"
          data-controls="prev"
          aria-controls="autoplay"
          onClick={scrollPrev}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
          </svg>
        </button> */}
        {/* <button
          className="button--next hidden md:block xl:hidden"
          data-controls="next"
          aria-controls="autoplay"
          onClick={scrollNext}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
          </svg>
        </button> */}
      </div>
    </div>
  );
}

export default HeaderBanner;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
// Types
import { OwnBrand } from 'types/brands';
// Components
import Image from 'next/image';
import Link from 'next/link';

interface IProps {
  ownBrand: OwnBrand;
  handleClickReset: () => void;
}

const returnExclusiveTileBackground = (brand: string): string => {
  let imgUrl = '';

  switch (brand) {
    case 'Touchpoint':
      imgUrl =
        'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto,h_125,w_200,g_face,c_thumb/v1597149517/ironmongery/brands/Own%20Brand%20Insitu%20Images/Touchpoint/Touchpoint_Cabinet_Handles_Insitu_4.jpg';
      break;
    case 'Altro':
      imgUrl =
        'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto,h_125,w_200,g_face,c_thumb/v1597150400/ironmongery/brands/Own%20Brand%20Insitu%20Images/Altro/Altro_DF_Front_Door_Insitu.jpg';
      break;
    case 'Elan':
      imgUrl =
        'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto,h_125,w_200,g_face,c_thumb/v1597417707/ironmongery/brands/Own%20Brand%20Insitu%20Images/Elan/Elan_DF_Front_Door_Insitu_6.jpg';
      break;
    case 'Vida':
      imgUrl =
        'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto,h_125,w_200,g_face,c_thumb/v1597152714/ironmongery/brands/Own%20Brand%20Insitu%20Images/Vida/Vida_Woodscrews_insitu_2.jpg';
      break;
    case 'Klug':
      imgUrl =
        'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto,h_125,w_200,g_face,c_thumb/v1597152459/ironmongery/brands/Own%20Brand%20Insitu%20Images/Klug/Klug_Sliding_Door_Wardrobe_Door_insitu.jpg';
      break;
    case 'Olde Forge':
      imgUrl =
        'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto,h_125,w_200,g_face,c_thumb/v1597151414/ironmongery/brands/Own%20Brand%20Insitu%20Images/Olde%20Forge/Olde_Forge_DF_Front_Door_insitu.jpg';
      break;
    case 'Hampstead':
      imgUrl =
        'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto,h_125,w_200,g_face,c_thumb/v1597151270/ironmongery/brands/Own%20Brand%20Insitu%20Images/Hampstead/Hampstead_Cabinet_Handles_Insitu.jpg';
      break;
    default:
      break;
  }

  return imgUrl;
};

const ExclusiveBrandTile: React.FC<IProps> = ({
  ownBrand,
  handleClickReset,
}): React.ReactElement => {
  const [backgroundImgUrl, setBackgroundImgUrl] = useState<string>('');
  const [isHoveredOver, setIsHoveredOver] = useState<boolean>(false);

  const generateURL = (brandName: string): string => {
    return brandName.replace(/ /g, '').replace(/&/g, '202620').replace(/'/g, '27');
  };

  useEffect(() => {
    setBackgroundImgUrl(returnExclusiveTileBackground(ownBrand.brandName));
  }, [ownBrand]);

  return (
    <div
      className="exclusive-brand-tile w-full h-full relative py-5 px-5 flex justify-center items-center cursor-pointer"
      onMouseEnter={() => setIsHoveredOver(true)}
      onMouseLeave={() => setIsHoveredOver(false)}
    >
      <Link href={`/brands/${generateURL(ownBrand.brandName.toLowerCase())}`}>
        <a title={ownBrand.brandName} role="button" tabIndex={0} onClick={handleClickReset}>
          <div className="absolute top-0 left-0 w-full h-full">
            {backgroundImgUrl.length && (
              <Image layout="fill" objectFit="cover" src={backgroundImgUrl} alt="background" />
            )}
          </div>
          {!isHoveredOver && (
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50" />
          )}
          <div
            className={`relative h-12 w-24 overflow-hidden rounded-full ${
              ownBrand.brandName !== 'Klug' ? 'bg-white' : 'bg-black-klug'
            }`}
          >
            <Image
              layout="fill"
              objectFit="contain"
              objectPosition="center"
              src={ownBrand.imageUrl}
              alt="brand tile"
            />
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ExclusiveBrandTile;
